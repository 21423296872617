import { Elm } from "../components/elm/Carousel.elm"
import { logDecodingError } from "../helpers/airbrake"
import { scrollLeftIntoView } from "../helpers/scroll_left_into_view"

type Carousel = {
  node: HTMLElement
  flags: Flags
  ports?: {
    carouselActivated: (carouselId: string) => void
    ready: () => void
  }
}

export default class ElmCarousel {
  readonly ports

  constructor(carousel : Carousel) {
    const { node, flags, ports } = carousel
    const app = Elm.Carousel.init({ node, flags })
    app.ports.flagDecodingError.subscribe(() => { logDecodingError(flags) })
    app.ports.scrollThumbnails.subscribe((targetId: string) => {
      window.requestAnimationFrame(() => {
        const target = document.getElementById(targetId)
        if (target) scrollLeftIntoView(target)
      })
    })

    if (ports) {
      app.ports.carouselActivated.subscribe(ports.carouselActivated)
      app.ports.ready.subscribe(() => {
        window.requestAnimationFrame(ports.ready)
      })
    }

    this.ports = app.ports
  }
}

export const initMyAccountCarousel = (): void => {
  const node = document.getElementById("elm-my-account-carousel")
  if (!node) return

  const flags = {
    classes: "bg:grey",
    indicators: true,
    name: "Account benefits",
    prefix: "account-benefits",
    slides: {
      before: [],
      selected: {
        iconClasses: "fa-calendar-day fa-fw fa-2x fa:green",
        text: "View your past and upcoming bookings"
      },
      after: [
        {
          iconClasses: "fa-gift-card fa-fw fa-2x fa:green",
          text: "Save your gift vouchers and manage your balance"
        },
        {
          iconClasses: "fa-stars fa-fw fa-2x fa:green",
          text: "Benefit from many more exclusive features"
        }
      ]
    }
  }

  new ElmCarousel({ node, flags })
}
